import React, { Component } from 'react'
import bina from '../../images/buildingcartoon.jpg'
import LocationSvg from '../svg/LocationSvg'
import NextArrowSvg from '../svg/NextArrowSvg'
import { Link } from 'react-router-dom'
import StudyAbroadPopup from '../StudyAbroadPopup'
import { connect } from 'react-redux'

export class Card12 extends Component {
  state = {
    showPopup: false,
  };


  togglePopup = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  };



  render() {
    const {showPopup} = this.state
    const{name, country_name, city_name, image, path, startFrom="", currency="", contentData}=this.props;
    return (
      
      <div className='p-[15px] rounded-[10px] bg-[#fff] shadow-md'>
        <div className='flex flex-col'> 
            <img src={image?image:bina} onError={(e)=>{e.target.src = bina}} className='w-full h-[197px] rounded-[10px]' />
            <div className='p-[15px_0px] flex flex-col gap-[15px] border-dashed border-b-[1px] border-[#000]'>
                <h3 className='text-[15px] font-[600] text-[#000]'>{name}</h3>
                <div className='flex items-center gap-[6px] '>
                    <LocationSvg width='16' height='16' fill='#7d7d7d' />
                    <div>
                      <span className='text-[11px] text-[#7D7D7D] font-[300]'>{country_name}</span>
                      <span className='text-[11px] text-[#7D7D7D] font-[300]'> / {city_name}</span>
                    </div>
                    
                </div>
            </div>

            <div className='p-[13px_0px] flex justify-between'>

              <div>
              <button onClick={this.togglePopup} className='border-[1px] border-[#4F46E5] p-[4px_8px] rounded-[50px] font-[500] text-[14px] text-[#4F46E5] '>{contentData?.video_sc_enroll}</button>
              <StudyAbroadPopup  show={showPopup} universty_name={name} country_name={country_name} onClose={this.togglePopup}></StudyAbroadPopup>
              </div>
              
                {/* <span className='text-[16px] font-[500] text-[#000]'>Starting from: {startFrom} {currency}</span> */}
                <Link to={path}>
                  <button className='border-[#4F46E5] border-[1px] p-[4px_8px]  flex items-center justify-center gap-[5px] rounded-[50px]'>
                      <span className='text-[#4F46E5] font-[500] text-[14px]'>{contentData?.card_details}</span>
                      <NextArrowSvg width='11' height='11' fill='#4F46E5' />
                  </button>
                </Link>

            </div>
        </div>
      </div>
      
      
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData, 
  lang_id: state.Data.lang_id
})

export default connect(mapStateToProps)( Card12)