import React, { Component } from 'react';
import norway from '../images/buildingcartoon.jpg';
import Card12 from '../components/cards/Card12';
import Card20 from '../components/cards/Card20';
import LoadMoreBtn from '../components/LoadMoreBtn';
import { getAcceptedStudent, getPublicUniversities, getSingleDataGO, getSingleDataSC } from '../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../actions/withHooks';
import { Helmet } from 'react-helmet';
import { getPathUrl } from '../actions/company_path';

export class SingleCountries extends Component {
  state = {
    countryData: '',
    page: 1,
    contacsPage: 0,
    count_id:''
  };

  componentDidMount() {
    const { params, getAcceptedStudent, getSingleDataSC, getPublicUniversities } = this.props;
   // countryId'yi buraya ekledik
    this.props.getSingleDataGO(`public-country-data?country_path=${this.props.params.id}&lang_id=1`)
      .then(resp => {
        this.setState({
          countryData: resp,
          count_id: resp.countries_id
        });
        getPublicUniversities(this.state.page, resp.countries_id);
        getAcceptedStudent(1,"", resp.countries_id); 
      });
    
  }

  loadMore = (page) => {
    const { getPublicUniversities, params } = this.props;
    getPublicUniversities(page, this.state.count_id);
  };


  loadMoreStudent=(page)=>{
    this.props.getAcceptedStudent(page, "", this.state.count_id)
  }
  render() {
    const { countryData, contacsPage } = this.state;
    const { publicUniversities, acceptedStudentsData, contentData } = this.props;
    // console.log(publicUniversities);
    // console.log(countryData)
    return (
      <div className='container'>
        <Helmet>
          <title>{countryData?.name}</title>
        </Helmet>
        <section>
          <img
            src={countryData?.countries?.image_full_url  ? countryData.countries.image_full_url : norway} 
            className='w-full max-h-[60vh] rounded-b-[20px]'
            alt='Country name'
          />
          <div className='flex justify-center relative  top-[-120px]  max-[650px]:top-[-60px] max-[400px]:top-[-38px]'>
            <span className='p-[20px_36px] font-[600] text-[35px] text-[#262626] rounded-[15px] bg-[#F3F4F6] max-[650px]:p-[10px_18px] max-[650px]:text-[24px] max-[400px]:text-[14px] max-[400px]:p-[6px_12px]'>
              {countryData?.name}
            </span>
          </div>
        </section>

        <section>
          <div className='flex items-center gap-[20px] mb-[32px] max-[400px]:flex-col max-[400px]:items-start'>
            <button
              onClick={() => this.setState({ contacsPage: 0 })}
              className={`${contacsPage === 0 ? "text-white bg-[var(--blue)]" : "text-[var(--blue)] border-[var(--blue)]"} p-[4px_12px] rounded-[50px] border-[1px] text-[17px] font-[500]`}
            >
              {contentData?.card_university}
            </button>
            <button
              onClick={() => this.setState({ contacsPage: 1 })}
              className={`${contacsPage === 1 ? "text-white bg-[var(--blue)]" : "text-[var(--blue)] border-[var(--blue)]"} p-[4px_12px] rounded-[50px] border-[1px] text-[17px] font-[500]`}
            >
              {contentData?.admitted_student}
            </button>
          </div>
        </section>

        {contacsPage === 0 ? (
          <section>
            <div className='grid grid-cols-3 gap-[20px] max-[1230px]:grid-cols-2 max-[700px]:grid-cols-1'>
              {publicUniversities?.map((data, i) => (
                <Card12
                  key={i}
                  name={data?.name}
                  country_name={data?.country}
                  city_name={data?.city}
                  image={data?.institutions?.images[0] ? data?.institutions?.images[0]?.image_full_url : norway}
                  path={`/university/${getPathUrl(data?.name)}`}
                />
              ))}
            </div>
            {publicUniversities?.length >= 20 ? (
              <LoadMoreBtn onLoadData={this.loadMore} defCount={20} count={publicUniversities?.length} country_id={this.props.params.id} />
            ) : null}
          </section>
        ) : (
          <div>
            <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
              {acceptedStudentsData?.map((data, i) => (
                <Card20
                  key={i}
                  first_name={data?.first_name}
                  last_name={data?.last_name}
                  company_name={data?.company_name}
                  image_full_url={data?.inst_image?.image_full_url}
                  country_image={data?.country_image}
                  country_name={data?.country_name}
                  user_image={data?.user_image}
                  program_name={data?.program_name}
                  inst_name={data?.inst_name}
                  ranking={data?.ranking}
                  company_id={data?.company_id}
                />
              ))}
            </div>
            {acceptedStudentsData?.length >= 20 ? (
              <LoadMoreBtn onLoadData={this.loadMoreStudent} defCount={20} count={acceptedStudentsData?.length} />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  publicUniversities: state.Data.publicUniversities,
  acceptedStudentsData: state.Data.acceptedStudentsData,
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
});

const mapDispatchToProps = { getSingleDataSC, getPublicUniversities, getAcceptedStudent, getSingleDataGO };

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleCountries));