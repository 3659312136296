import React, { Component } from 'react'
import { withHooks } from '../actions/withHooks';
import { getEnglishExamResults, getSingleData } from '../actions/MainAction';
import { connect } from 'react-redux';
import SectionHeader from '../components/SectionHeader';
import SliderSection from '../components/SliderSection'
import VideoSection from '../components/VideoSection';
import { changeStateValue } from '../redux/MainReducer';
import { Helmet } from 'react-helmet';
export class SingleExams extends Component {
    state={
        examData:'',
    }
    onlickSlide(index, e){
      this.setState({play:index})
    }
    componentDidMount(){
      const {params, lang_id, getSingleData, getEnglishExamResults} = this.props;
      // console.log(params)
        getSingleData(`exams/${params.id}?lang_id=${lang_id}`)
        .then(resp=>{
          // console.log(resp)
            this.setState({examData:resp})
        })
        window.scrollTo(0,0)

        if(params.id == 11 || params.id == 13 || params.id == 14 ){
          getEnglishExamResults(0.30)
        }else{
          this.props.changeStateValue({
            name:"englishExamResults",
            value:[]
          })
        }
    }
    
  render() {
  
    const {examData, play, sectionfixed} = this.state;
    const {englishExamResults} = this.props;
    // console.log(examData);
    // console.log(englishExamResults)
    // console.log("got ferid")
    return (
      <div  className='container '>
        <Helmet>
          <title>{examData?.translate?.title}</title>
        </Helmet>
        <section className='flex single_exams gap-[60px] sticky top-0 items-start   max-[1080px]:flex-col-reverse max-[1080px]:items-center '>
          
          <div className='  h-auto w-full gap-[32px]  max-w-[760px]'>
            {/* <img className=' w-full rounded-[6px]' src={examData?.image_full_url} /> */}
            <SectionHeader title={examData?.translate?.title} />
            <div className='flex flex-col gap-[12px] w-full singleexamheader' dangerouslySetInnerHTML={{__html: examData?.translate?.text}}>
            </div>

            <SliderSection exam_type_id={examData?.exam_type_id} faqs={examData?.faqs} overView={examData?.translate?.description} />
          </div>
              
          <VideoSection examData={examData} />
        </section>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  englishExamResults: state.Data.englishExamResults,

});
const mapDispatchToProps = {getSingleData, getEnglishExamResults, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SingleExams)