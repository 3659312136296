import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import LogoSlider from '../components/LogoSlider'
import Slider from 'react-slick'
import { getAcceptedStudent, getCountries, getStatistics } from '../actions/MainAction'
import { connect } from 'react-redux'
import CountriesSection from '../components/CountriesSection'
import Card20 from '../components/cards/Card20'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'



export class StudyAbroad extends Component {

  componentDidMount() {
    // const {getAcceptedStudent, getStatistics} = this.props;
    this.props.getAcceptedStudent(0.4);
    this.props.getStatistics( this.props.params_id);

  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const {acceptedStudentsData, countriesData, statisticsData, contentData} = this.props;
    // console.log(statisticsData);
    // console.log(acceptedStudentsData);
    return (
      <div>
        <Helmet>
        <title>{contentData?.study_abroad_title}</title>
        <meta name="description" content="Xaricde tehsil arzularınıza çatmağa, sizə CELT kömək edəcək. Həm Amerikada, həm Avropada, həm də Turkiyede tehsil fürsətlərini əldən verməyin!" />
        <meta property="og:locale" content="az_AZ" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Xaricde Tehsil - Avropa, Amerika və Turkiyede tehsil - Xaricde Dil Kurslari" />
        <meta property="og:description" content="Xaricde tehsil arzularınıza çatmağa, sizə CELT kömək edəcək. Həm Amerikada, həm Avropada, həm də Turkiyede tehsil fürsətlərini əldən verməyin!" />
        <meta property="og:url" content="https://celt.az/xaricde-tehsil/" />
        <meta property="og:site_name" content="CELT Colleges" />
        <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
        <meta property="article:modified_time" content="2022-08-16T14:43:28+00:00" />
        <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@celtcolleges" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="3 minutes" />
     
        </Helmet>
        <SectionHeader title={contentData?.sh_admitted_students} />
        <div className='border-[1px] rounded-[12px] p-[12px] container'>
          <Slider  {...settings}>
            {
              acceptedStudentsData?.map((data, i)=>{
                  return(
                      <Card20 
                      key={i}
                      first_name = {data?.first_name}
                      last_name = {data?.last_name}
                      company_name = {data?.company_name}
                      image_full_url = {data?.inst_image?.image_full_url}
                      country_image = {data?.country_image}
                      country_name={data?.country_name}
                      user_image={data?.user_image}
                      program_name={data?.program_name}
                      inst_name={data?.inst_name}
                      ranking={data?.ranking}
                      company_id={data?.company_id}
                      
                      /> 
                  )
              })
            }
          </Slider>

            <div className=' flex justify-center  mt-[12px] ' >
              <Link to='/qebul-olmus-telebeler'>
                <button className='text-[var(--blue)] p-[6px_16px]  text-[16px] font-[600] border-[var(--blue)] rounded-[20px] border-[1px]'>
                  {contentData?.btn_view_all}
                </button>
              </Link>
            </div>
        </div>
       
        <section className='pt-[52px] container'>
          <SectionHeader title={contentData?.sh_partners} />
          <LogoSlider />
        </section>


        <section className='container pt-[52px]'>
          <div className='pb-[52px]'>
            <SectionHeader title={contentData?.sh_countries}/>

          </div>
          <CountriesSection />
        </section>

        {/* <FaqSection /> */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  acceptedStudentsData: state.Data.acceptedStudentsData,
  statisticsData: state.Data.statisticsData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,

})
const mapDispatchToProps = {getAcceptedStudent, getCountries, getStatistics}
export default connect(mapStateToProps, mapDispatchToProps ) (StudyAbroad)