import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux'
import Card13 from '../components/cards/Card13'
import { getGraduats } from '../actions/MainAction'
import LoadMoreBtn from '../components/LoadMoreBtn'
import { Helmet } from 'react-helmet'
import favicon from './celt-title.jpeg'





export class GraduatedStudents extends Component {
  componentDidMount(){
    this.props.getGraduats(1)
    window.scrollTo(0,0)
  }

  loadMore=(page)=>{
    this.props.getGraduats(page)
  }
  render() {
    const {contentData, graduatsData} = this.props;
    // console.log(graduatsData)
    return (
      <div className='container'>
        <Helmet>
          <title>{contentData?.graduated_student_title}</title>
          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Xaricdə Tələbələrimiz" />
          <meta property="og:description" content="Seçilmiş Məzunlarımız M. Aliyeva Diplom: High School Ortalama: 90 Məktəb: CELT High School   Bakalavr: Economics and Business T. Ocağı: University of Amsterdam Ölkə: Holladiya F. Huseynov Diplom: High School &hellip; Xaricdə Tələbələrimiz Davamını oxu»" />
          <meta property="og:url" content="https://celt.az/xaricde-oxuyanlar/" />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2023-06-15T11:35:17+00:00" />
          <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="23 minutes" />
          <link id="favicon" rel="icon" href={favicon} type="image/x-icon"/>
        </Helmet>


        <SectionHeader title={contentData?.sh_highscholl}/>
        <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
          {
            graduatsData?.map((data, i)=>{
              return(
                <Card13 
                  key={i}
                  first_name = {data?.first_name}
                  last_name = {data?.last_name}
                  company_name = {data?.company_name}
                  image_full_url = {data?.inst_image?.image_full_url}
                  country_image = {data?.country_image}
                  country_name={data?.country_name}
                  user_image={data?.user_image}
                  program_name={data?.program_name}
                  inst_name={data?.inst_name}
                  ranking={data?.ranking}
                  grade={data?.grade}
                  company_id={data?.company_id}
                  
                /> 
              )
            })
          }             
        </div>
        {
          graduatsData?.length >=20?
          <LoadMoreBtn onLoadData={this.loadMore} defCount={20} count={graduatsData?.length} />:null
        }

      
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  graduatsData: state.Data.graduatsData,
  contentData: state.Data.contentData,
})
const mapDispatchToProps = {getGraduats}
export default connect(mapStateToProps, mapDispatchToProps) (GraduatedStudents)
