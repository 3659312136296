import React, { Component } from 'react'

export class Card24 extends Component {
  render() {
    const {reply_name,reply_date,reply_comment }=this.props;
    return (
        <div className='p-[65px] bg-[#f7f7f7] mt-[20px] shadow-md ml-[40px] max-[600px]:p-[17px]'>
        <div className='flex gap-[30px] max-[600px]:flex-col'>
            {/* <div>
                <img className='rounded-[50%] w-[80px] h-[80px]' src={avatar} />
            </div> */}

            <div className='flex flex-col gap-[20px] w-[90%]'>
                <div className='flex justify-between '>
                    <div>
                        <h4 className='font-[600] text-[#313e3b] text-[18px]'>{reply_name}</h4>
                        <span className='text-[14px] font-[400] text-[#828c8a]' >{reply_date}</span>
                    </div>

                    {/* <div>
                        <button className='p-[6px_20px] border-[1px] rounded-[50px] text-[11px] border-[#828c8a] text-[#828c8a]'>
                            REPLY
                        </button>
                    </div> */}
                    
                </div>

                <div>
                    <p className='text-[17px] font-[400] text-[#828c8a]'>
                        {reply_comment}
                    </p>
                </div>
            </div>

        </div>
    </div>
    )
  }
}

export default Card24