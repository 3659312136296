import axios from "axios"
import { CELTGO_API, MAIN_API, PUBLIC_API, STUDY_COACH } from "../MAIN_API"
import {changeStateValue} from "../redux/MainReducer";

export const getMainInfo=(lang_id="1")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/main-info`,{
        params:{lang_id:lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'mainInfoData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const getFaqData=(lang_id='1',page_type="")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/faq`,{
        params:{lang_id:lang_id, page_type}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'faqData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const getLangPrepationData=(lang_id="1")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/exams`,{
        params:{lang_id:lang_id, page_type:2}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'langPrepationData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getStudyAbroadData=(lang_id="1")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/study-abroad`,{
        params:{lang_id:lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'studyAbroadData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getKidsEnglishType=(lang_id="1", schoolType)=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/kids-english-type`,{
        params:{lang_id:lang_id, school_type:schoolType}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'kidsEnglishTypeData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getWhyUs=(lang_id="1")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/why-us`,{
        params:{lang_id:lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'whyUsData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getstudentsReviewsData=(lang_id="1", count=3, company_id="")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/student-reviews`,{
        params:{lang_id:lang_id, count, company_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'studentsReviewsData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getContent=(lang_id="1")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/content`,{
        params:{lang_id:lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'contentData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getExamsData=(lang_id="1")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/exams`,{
        params:{lang_id:lang_id, page_type:1}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'examsData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}
export const getSingleData=(url)=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/${url}`).then(resp=>{
        dispatch(changeStateValue({name:'loader', value:false}))
        return resp?.data
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        return ""
    })
}
export const getSingleDataSC=(url)=>async dispatch => {
    // console.log(url)
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${STUDY_COACH}/${url}`).then(resp=>{
        dispatch(changeStateValue({name:'loader', value:false}))
        return resp?.data
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        return ""
    })
}
export const getSingleDataGO=(url)=>async dispatch => {
    // console.log(url)
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${CELTGO_API}/${url}`).then(resp=>{
        dispatch(changeStateValue({name:'loader', value:false}))
        return resp?.data
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        return ""
    })
}

export const getprimaryCompanies=()=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/companies`,{
        params:{type:1}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'primaryCompaniesData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const getkidsCompanies=(lang_id)=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/companies`,{
        params:{type:2}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'kidsCompaniesData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const getschoolsCompanies=()=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/companies`,{
        params:{type:3}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'schoolsCompaniesData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const getSingleBranch=(id)=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/companies/${id}`,{
        params:{type:2}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'companyData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const getExamResults=(page, type, exam_type_id, companyId="")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${PUBLIC_API}/public-company-student-exam`,{
        params:{page, type, exam_type_id, companyId}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'loader',
            value:false
        }))
        return resp.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        return []
    })
    
}





export const getCountries=()=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${CELTGO_API}/public-countries`,{
        params:{type:1, uni_count:'count', page:3}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'countriesData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}


export const getUniversityLogo=()=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${CELTGO_API}/university-logos`,{
        params:{type:1}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'universityLogoData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const getBlogs=(lang_id, page)=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/blogs`,{
        params:{page, lang_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'blogsData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}


export const getSingleBlog=(path, navigate)=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${MAIN_API}/blogs-show`,{
        params:{path}
    }).then(resp=>{
        dispatch(changeStateValue({ name:'loader', value:false}))
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        navigate('/error');
        return ""
    })
}

// export const getBlogComments=(page=1, blog_id)=>async dispatch => {
//     dispatch(changeStateValue({name:'loader', value:true}))
//     return await axios.get(`${MAIN_API}/blog-comments`,{
//         params:{page, blog_id}
//     }).then(resp=>{
//         dispatch(changeStateValue({ name:'blogComments', value:resp?.data}))
//         return resp?.data
//     }).catch(err=>{
//         console.log(err.response)
//         dispatch(changeStateValue({name:'loader', value:false}))
//         return ""
//     })
// }

export const getBlogComments = (page = 1, blog_id) => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));
    try {
        const response = await axios.get(`${MAIN_API}/blog-comments`, {
            params: { page, blog_id }
        });
        const blogComments = response?.data || [];
        // Her yorumu döngüyle kontrol et ve gelen yanıtın içindeki her bir yorumun "reply" dizisini kontrol et
        blogComments.forEach(comment => {
            if (comment.reply && comment.reply.length > 0) {
                // reply dizisi var ve içinde veri var, bu yorumun yanıtları var demektir
                // Burada istediğiniz işlemleri yapabilirsiniz
                comment.reply.forEach(reply => {
                    // Her bir yanıtı kontrol et
                    console.log(reply);
                    // Örnek olarak, yanıtın içindeki veriyi konsola yazdıralım
                });
            }
        });
        dispatch(changeStateValue({ name: 'blogComments', value: blogComments }));
        dispatch(changeStateValue({ name: 'loader', value: false }));
        return blogComments;
    } catch (error) {
        console.error('Error fetching blog comments:', error);
        dispatch(changeStateValue({ name: 'loader', value: false }));
        return [];
    }
};

export const getStatistics=()=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${CELTGO_API}/education-stats`,{
        params:{type:1}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'statisticsData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}

export const postEmail = (data) => async dispatch => {
    const {email} = data;

    const postData = {
        email
    };
    
    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.post(`${MAIN_API}/email-collect`, postData)
    .then(resp=>{   
        dispatch(changeStateValue({name:'loader', value:false}))
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        return "error"
    })
}

export const postStudyAbroad = (data) => async dispatch => {
    const {name, phone_number, country, university, blog, program, degree  } = data;

    const postData = {
        name,
        phone_number,
        country,
        university,
        blog,
        program,
        degree
    };
    
    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.post(`${STUDY_COACH}/celtaz-applies`, postData)
    .then(resp=>{   
        dispatch(changeStateValue({name:'loader', value:false}))
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        return "error"
    })
}


export const postStatistics = (data) => async dispatch => {
    const { name, company_id, subject, phone_number, school_type } = data;

    // Parametreleri hazırlayalım
    const postData = {
        name,
        school_type,
        company_id: company_id, // Online ise null, offline ise company_id
        subject,
        phone_number
    };


    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.post(`${MAIN_API}/online-apply`, postData)
    .then(resp=>{   
        dispatch(changeStateValue({name:'loader', value:false}))
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
        return "error"
    })
};


export const getGraduats=(page=1, company_id="")=>async dispatch => {
    dispatch(changeStateValue({name:'loader', value:true}))
    return await axios.get(`${CELTGO_API}/high-sch-accepted-students`,{
    params:{page, company_id}
    }).then(resp=>{
        dispatch(changeStateValue({
            name:'graduatsData',
            value:resp?.data
        }))
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:'loader', value:false}))
    })
}


export const getPublicUniversities=(page=1, country_id="")=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${CELTGO_API}/public-institution`,{
        params: {page, filterByCountry:country_id}
    })
    .then(resp=>{
        dispatch(changeStateValue({
            name:"publicUniversities",
            value:resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}

export const getPublicPrograms=(page=1, university_id, degree_id)=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${CELTGO_API}/public-programs`,{
        params: {page, filterByInstitution:university_id, filterByDegree:degree_id}
    })
    .then(resp=>{
        dispatch(changeStateValue({
            name:"publicPrograms",
            value:resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}

export const getDegreeByUniversity=(university_id)=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${CELTGO_API}/public-educationDegree-by-uni`,{
        params: {lang_id:1, uni_id:university_id}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"degreeByUniversity", value:resp?.data}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}));
        return []
    })
}


// export const getAcceptedStudent=(page=1, id, countryId)=>async dispatch => {
//     dispatch(changeStateValue({name:'loader', value:true}))
//     return await axios.get(`${CELTGO_API}/accepted-students`,{
//         params:{page,lang_id:1, companyId:id, countryId}
//     }).then(resp=>{
//         dispatch(changeStateValue({
//             name:'acceptedStudentsData',
//             value:resp?.data
//         }))
//     }).catch(err=>{
//         console.log(err.response)
//         dispatch(changeStateValue({name:'loader', value:false}))
//     })
// }


export const getAcceptedStudent = (page = 1, id, countryId="") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.get(`${CELTGO_API}/accepted-students`, {
        params: { page, lang_id: 1, companyId: id, countryId }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'acceptedStudentsData',
            value: resp?.data
        }));
        dispatch(changeStateValue({ name: 'loader', value: false }));
    }).catch(err => {
        console.log(err.response);
        dispatch(changeStateValue({ name: 'loader', value: false }));
    });
};
export const getPremiumStudents = (lang_id=1) => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.get(`${CELTGO_API}/admin-prem-st`, {
        params: {  lang_id:lang_id}
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'adminPremiumStudents',
            value: resp?.data
        }));
        dispatch(changeStateValue({ name: 'loader', value: false }));
    }).catch(err => {
        console.log(err.response);
        dispatch(changeStateValue({ name: 'loader', value: false }));
    });
};
export const getPremiumStudentData = (id, lang_id=1) => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.get(`${CELTGO_API}/admin-prem-st/${id}`, {
        params: {  lang_id:lang_id}
    }).then(resp => {
       
        dispatch(changeStateValue({ name: 'loader', value: false }));
        return resp?.data
    }).catch(err => {
        console.log(err.response);
        dispatch(changeStateValue({ name: 'loader', value: false }));
        return ''
    });
};
export const getEnglishExamResults = (page=1, company_id="") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));
    return await axios.get(`${MAIN_API}/vip-exam-results`, {
        params: {page, company_id}
    }).then(resp => {
       
        dispatch(changeStateValue({ name: 'englishExamResults', value: resp?.data }));
        return resp?.data
    }).catch(err => {
        console.log(err.response);
        dispatch(changeStateValue({ name: 'loader', value: false }));
        return ''
    });
};