import React, { Component } from 'react';
import Logo from '../../images/celt_english_logo-01.jpg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FacebookSvg from '../svg/FacebookSvg';
import Instagram from '../svg/Instagram';
import RightArrowSvg from '../svg/RightArrowSvg';
import LinkedinSvg from '../svg/LinkedinSvg';
import { postEmail } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';

export class Footer extends Component {
  state = {
    inputValue: '',
    email: '',
    error: '',
    errornumber: '',
    successMessage: "",
    btnDisable: false
    
  };

  handleChangePhone = (event) => {
    const value = event.target.value;
    if (value.length <= 9) {
      this.setState({ inputValue: value });
    }
  };

  handleChange = (event) => {
    this.setState({ email: event.target.value, error: '' });
  };

  handleSubmit = () => {
    
    const { email} = this.state;
    const {postEmail} = this.props;
    
   

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim()) {
      this.setState({ error: 'Emailinizi qeyd edin' });
      return;
    }

    if (!emailRegex.test(email)) {
      this.setState({ error: 'Email adresinizi düzgün yazdığınızdan əmin olun' });
      return;
    }

    const data ={email}
    // console.log(data);

    this.setState({btnDisable:true})

    postEmail(data)
    .then((resp)=>{
      if(resp === "success"){
        this.setState({ successMessage: 'Göndərildi', error:'',  email: ''})
        this.setState({btnDisable:false})
      
      }
    })
    .catch((error) => {
      this.setState({ successMessage: '', error: 'Email adresinizi düzgün yazdığınızdan əmin olun' });
      this.setState({ btnDisable: false }); // Hata durumunda düğmeyi tekrar etkinleştir
    });
  }

  render() {
    const { email, error, successMessage, btnDisable} = this.state;
    const { contentData } = this.props;

    return (
      <footer className='w-full bg-[#2b3734] mt-[62px]'>
        <div className='container'>
          {/* <div className='flex justify-center'>
            <FooterCards />
          </div> */}
          <div className='grid grid-cols-4 pt-[70px] gap-[32px] max-[1000px]:grid-cols-3 max-[750px]:grid-cols-2 max-[500px]:grid-cols-1 max-[500px]:gap-[40px]'>

            <div className='flex flex-col items-start gap-[16px] max-[750px]:items-center max-[500px]:gap-[12px]'>
              <Link to="/">
                <img src={Logo} alt="Logo" className="w-[115px] h-[70px] rounded-[8px]" />
              </Link>
              {/* <span className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] text-start max-w-[275px] max-[750px]:text-center'>
                {contentData?.ft_info_text}
              </span> */}
              <div className='flex items-center gap-[4px]'>
                <Link to='https://www.instagram.com/celtcollegesofficial/?hl=en' target='_blank'>
                  <Instagram width='32' height='32' fill='#fff' />
                </Link>

                <Link  to='https://www.facebook.com/celtscollege/' target='_blank'>
                <FacebookSvg width='24' height='24' fill='#fff' />
                </Link>
                <LinkedinSvg width='19' height='19' fill='#fff' />
              </div>
            </div>

            <div className='flex flex-col items-start gap-[8px] max-[750px]:items-center max-[500px]:gap-[12px]'>
              <h4 className='font-[500] text-[18px] text-[#fff] opacity-1'>{contentData?.main_project}</h4>
              <Link to='/schools'>
                <span className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] hover:text-[var(--hover-gray)] cursor-pointer'>{contentData?.nav_school}</span>
              </Link>
              
              <Link to='/contactus/'>
              <span className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] hover:text-[var(--hover-gray)] cursor-pointer'>{contentData?.ft_4}</span>
              </Link>
              <Link to='/xaricde-tehsil/'>
                <span className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] hover:text-[var(--hover-gray)] cursor-pointer'>{contentData?.study_abroad}</span>
              </Link>
              <Link to='/blogs'>
                <span className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] hover:text-[var(--hover-gray)] cursor-pointer'>{contentData?.nav_blog}</span>
              </Link>
            </div>

            <div className='flex flex-col items-start gap-[8px] max-[750px]:items-center max-[500px]:gap-[12px]'>
              {/* <h4 className='font-[500] text-[18px] text-[#fff] opacity-1'>{contentData?.ft_6}</h4> */}
              {/* <div className='flex flex-col max-[750px]:items-center'>
                <span className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] cursor-pointer'>{contentData?.ft_7}</span>
                <div className='flex items-center gap-[4px]'>
                  <a href='tel:+994552107557' target='__blank' className='text-[#fff] cursor-pointer font-[500] text-[17px] hover:text-[var(--orange)]'>+994 55 210 7557</a>
                </div>
              </div> */}
              <div className='flex flex-col items-start gap-[8px] max-[750px]:items-center'>
                <span className='font-[500] text-[18px] text-[#fff] opacity-1'>{contentData?.ft_8}</span>
                <a href='mailto:hello@celt.az' target='_blank' className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] hover:text-[var(--hover-gray)] cursor-pointer'>hello@celt.az</a>
              </div>

              <div className='flex flex-col items-start gap-[8px] max-[750px]:items-center'>
                <span className='font-[500] text-[18px] text-[#fff] opacity-1'>{contentData?.vacancy_email}</span>
                <a href='mailto:hr@celt.az' target='_blank' className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400] hover:text-[var(--hover-gray)] cursor-pointer'>hr@celt.az</a>
              </div>
            </div>

            <div className='flex flex-col items-start gap-[10px] max-[1000px]:items-center max-[750px]:justify-center max-[500px]:gap-[12px]'>
              <div className='flex flex-col gap-[6px]'>
                <span className='font-[500] text-[18px] text-[#fff] opacity-1 max-[1186px]:text-center'>{contentData?.fr_subscribe}</span>
                <span className='text-[var(--medium-gray)] leading-[28px] text-[17px] font-[400]'>{contentData?.ft_news}</span>
              </div>

              <div className='w-full flex flex-col'>
                <form className='flex justify-between p-[10px] items-center border-[1px] border-[var(--medium-gray)] rounded-[6px]' onSubmit={this.handleSubmit}>
                  <input
                    type='email'
                    placeholder='E-poçtunuzu daxil edin'
                    id='mail'
                    className='footerinp rounded-[6px] w-full p-[5px] text-[#fff] text-[12px] bg-[#2b3734]'
                    value={email}
                    onChange={this.handleChange}
                  />
                </form>
                <div className='mt-[4px]'>
                  {successMessage && <span className=' text-[#0fff50] text-[14px] font-[500]'>{successMessage}</span>}
                  {error && <span className='text-[red] text-[14px] font-[500]'  >{error}</span>}
                </div>
                
                <div className='flex mt-[14px]  justify-center'>
                    <button 
                      onClick={this.handleSubmit}
                      disabled={btnDisable}
                      className='flex items-center justify-center p-[10px_40px] rounded-[8px] border-[var(--medium-gray)] border-[1px]'>
                      <span className='text-[13px] mb-[3px] font-[500] text-[#fff]'>{contentData?.btn_submit}</span>
                      <RightArrowSvg width='14px' height='14px' fill='#fff' />
                    </button>
                  </div>
              </div>

            </div>
          </div>

          <div className='flex justify-between items-center mt-[32px] pb-[32px] gap-[30px] pt-[32px] border-t-[1px] border-gray-400 max-[1200px]:flex-col'>
            <div>
              <ul className='flex gap-[20px] flex-wrap justify-center items-center'>
                <li>
                  <Link to="/schools" className="text-[16px] hover:text-[var(--hover-gray)] text-[var(--medium-gray)]">
                    {contentData?.nav_school}
                  </Link>
                </li>
                <li>
                  <Link to="/xaricde-oxuyanlar/" className="text-[16px] hover:text-[var(--hover-gray)] text-[var(--medium-gray)]">
                    {contentData?.nav_1}
                  </Link>
                </li>
                <li>
                  <Link to="/ingilis-dili-kurslari/" className="text-[16px] hover:text-[var(--hover-gray)] text-[var(--medium-gray)]">
                    {contentData?.nav_2}
                  </Link>
                </li>
                <li>
                  <Link to="/imtahanlar/" className="text-[16px] hover:text-[var(--hover-gray)] text-[var(--medium-gray)]">
                    {contentData?.nav_5}
                  </Link>
                </li>
                <li>
                  <Link to="/xaricde-tehsil/" className="text-[16px] hover:text-[var(--hover-gray)] text-[var(--medium-gray)]">
                    {contentData?.nav_3}
                  </Link>
                </li>
                <li>
                  <Link to="/contactus/" className="text-[16px] hover:text-[var(--hover-gray)] text-[var(--medium-gray)]">
                    {contentData?.nav_6}
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <span className='text-[16px] text-end text-[var(--medium-gray)]'>
                © 2024 All Rights Reserved
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData
});

const mapDispatchToProps = {postEmail, changeStateValue}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);