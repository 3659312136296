import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import Card8 from '../components/cards/Card8';
import { getprimaryCompanies } from '../actions/MainAction';

export class StudyCenters extends Component {
  componentDidMount() {
    this.props.getprimaryCompanies(this.props.lang_id);
  }

  // Fonksiyon, bir dizi içindeki öğeleri rastgele sıralamak için kullanılabilir
  shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  render() {
    const {contentData, primaryCompaniesData} = this.props;
    const shuffledCompanies = this.shuffleArray(primaryCompaniesData);

    return (
      <div className='container'>
        <Helmet>
          <title>{contentData?.sh_study_centers}</title>
        </Helmet>
        <SectionHeader title={contentData?.sh_study_centers} />

        {/* <PrimaryCompanies /> */}

        <div className='grid grid-cols-3 gap-[32px] mt-[52px]  max-[1000px]:grid-cols-2 max-[600px]:grid-cols-1'>
        {
          shuffledCompanies?.map((data, i) => (
            <Card8
              key={i}
              title={data?.title}
              // phone_number={data?.phone_number}
              // location={data?.location}
              // facebook={data?.facebook}
              // instagram={data?.instagram}
              // office_number={data?.office_number}
              id={data?.id}
              path={`${data?.path}`}
              // image_full_url={data?.image_full_url}
            />
          ))
        }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
  contentData: state.Data.contentData,
  primaryCompaniesData: state.Data.primaryCompaniesData,
})
const mapDispatchToProps = {
  getprimaryCompanies
}
export default connect(mapStateToProps, mapDispatchToProps)( StudyCenters)