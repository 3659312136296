import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import defaultImage from '../../images/building.png'
import { connect } from 'react-redux';
import StudyAbroadPopup from '../StudyAbroadPopup';

export class Card16 extends Component {
  state = {
    showPopup: false,
  };


  togglePopup = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  };
  render() {
    const { showPopup } = this.state;
    const { name, image_full_url, institution_count, path,contentData} = this.props;
    const imageUrl = image_full_url && image_full_url.trim() !== '' ? image_full_url : defaultImage;
    // institution_count 4 veya daha büyükse bileşeni göster
    if (institution_count >= 4) {
      return (
        <div className='flex flex-col items-center'>
          <Link to={path}>
            <div className='flex flex-col items-center gap-[20px]'>
              <img className='rounded-[50%] w-[221px] h-[221px]' src={imageUrl} alt={name} />
              <div className='flex flex-col items-center'>
                <h3 className='text-[20px] font-[400] '>{name}</h3>

                <div className='flex items-center justify-center gap-[5px]'>
                  <span className='text-[var(--medium-gray)] text-[15px] font-[400]'>{institution_count}</span>
                  <span className='text-[var(--medium-gray)] text-[15px] font-[400]'>{contentData?.card_university}</span>
                </div>
              </div>
            </div>
          </Link>

          <button onClick={this.togglePopup} className='border-[1px] border-[#4F46E5] p-[4px_8px] rounded-[50px] font-[500] text-[14px] text-[#4F46E5] mt-[4px]'>{contentData?.video_sc_enroll}</button>
          <StudyAbroadPopup  show={showPopup} country_name={name} onClose={this.togglePopup}></StudyAbroadPopup>
        </div>
        
        
      );
    } else {
      // institution_count 4'ten küçükse bileşeni gösterme
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData, 
  lang_id: state.Data.lang_id
})

export default connect(mapStateToProps) (Card16)
