import React, { Component } from 'react';
import GraduationSvg from '../svg/GraduationSvg';
import Location from '../svg/LocationSvg';
import DiplomaSvg from '../svg/DiplomaSvg';
import { Link } from 'react-router-dom';
import University from '../svg/University';
import rank1 from "../../images/rankings/rank1.png";
import rank2 from "../../images/rankings/rank2.png";
import rank3 from "../../images/rankings/rank3.png";
import rank4 from "../../images/rankings/rank4.png";
import { connect } from 'react-redux';
import { getCompanyPath } from '../../actions/company_path';
export class Card13 extends Component {


  getNameText(first_name, last_name) {
    const firstInitial = first_name ? first_name.charAt(0) : '';
    const lastInitial = last_name ? last_name.charAt(0) : '';
    return firstInitial + lastInitial;
  }
  getRandImage(){
    let images = [rank1, rank2, rank3, rank4];
    let rand = Math.floor(Math.random()*4)
    return images[rand]
  }

  getName(name) {
    const splt = name.split(' ');
    let txt = '';
    if (splt.length > 0) {
      txt += splt[0][0].toUpperCase(); // İlk kelimenin ilk harfi
      if (splt.length > 1) {
        txt += splt[splt.length - 1][0].toUpperCase(); // Son kelimenin ilk harfi
      }
    }
    return txt;
  }

  getStr(text){
    // console.log(text)
    return text.replace(/<[^>]+>/g, ' ');
  }

  splitText(text) {
    if (text) {
      if (text.length > 30) {
        return text.substring(0, 30) + "...";
      } else {
        return text;
      }
    }
    return '';
  }
  render() {
    const {
      first_name,
      image_full_url,
      user_image = "",
      last_name,
      company_name,
      country_image,
      country_name,
      program_name,
      inst_name,
      ranking = "",
      grade,
      company_id,
      contentData
    } = this.props;



    return (
      <div className="rounded-[8px_8px_8px_8px] pb-[8px] bg-[#fff] shadow m-[10px] relative">
        <div className="flex justify-end mr-[15px] rounded-[8px_8px_0px_0px] h-[60px] absolute z-40 w-full">
        {
          ranking <=500?
          <div className='relative flex justify-center items-center mr-[10px] mt-[10px]'>
            <img style={{ width: '50px', height:'55px' }} src={this.getRandImage(ranking)} />
            <span className='absolute text-[20px] text-white font-black mt-[9px] different-font'>{ranking}</span>
          </div>:null
          }
        </div>  
       
        <div className="background-image-cont relative">
          <img className="w-full h-full rounded-[8px_8px_0_0]" src={image_full_url} />
        </div>
        <div className="p-[0_15 px_15px_15px]">
          <div className="pl-[10px] flex flex-col gap-[5px] w-297 clip-custom bg-[var(--white)] rounded-[0px_0px_8px_8px]">
            {user_image !== "" ? (
              <img
                className="absolute w-[80px] h-[80px] rounded-[50px] border-[4px] border-white mt-[-90px]"
                src={user_image}
              />
            ) : (
              <span className="w-[80px] h-[80px] rounded-[50px] inline-flex justify-center items-center text-white text-[27px] font-[500] bg-blue-700">
                {this.getNameText(first_name, last_name)}
              </span>
            )}
            <div className="p-[8px] flex flex-col gap-[20px]">
              <div className="flex items-center gap-[4px]">
                <h3 className="font-[700] text-[18px] text-[var(--blue)]">{this.getName(first_name)}.</h3>
                <h3 className="font-[700] text-[18px] text-[var(--blue)]">{last_name}</h3>
              </div>
              <section>
                <div className="flex flex-col gap-[8px] border-b-[1px] pb-[15px]">
                  <p className="text-[14px] font-[700] text-[#000]" title={inst_name} >{inst_name}</p>
                  <div className="flex flex-col gap-[6px] ml-[5px]">
                    <div className="flex items-center gap-[8px] ml-[-3px]">
                      <GraduationSvg />
                      <span className="text-[12px] font-[500] text-[black]" title={program_name}>{this.splitText(this.getStr(program_name))}</span>
                    </div>
                    <div className="flex items-center gap-[15px]">
                      <img className="w-[20px] h-[12px]" src={country_image} />
                      <span className="text-[12px] font-[500] text-[black]">{country_name}</span>
                    </div>
                    <div className="flex items-end gap-[16px] ml-[0px]">
                      <University />
                      <span className="text-[12px] font-[500] text-[black]">{contentData?.card_acceptance}</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-[6px] pt-[15px]">
                  <div>
                    <p className="text-[14px] font-[700] text-[#000] mb-[6px]">{contentData?.card_diploma}</p>
                  </div>
                  <div className="ml-[4px] flex flex-col gap-[6px]">
                    <div className="flex items-center gap-[12px]">
                      <div>
                        <DiplomaSvg />
                      </div>
                      <span className="text-[12px] font-[500] text-[black]">{contentData?.high_scholl}</span>
                      <div className="flex items-center gap-[6px]">
                        <span className="text-[12px] font-[600] text-[var(--blue)]">{contentData?.gpa}</span>
                        <span className="text-[12px] font-[500] text-[black]">{grade}</span>
                      </div>
                    </div>
                    <div className="flex items-center  h-[20px] gap-[10px] ml-[-2px]">
                      <div className='flex items-center'>
                        <Location width="24" height="24" fill="var(--blue)" />

                      </div>
                      <Link to={getCompanyPath(company_id)} className="text-[#0066FF] flex items-center h-full text-[14px] font-[500]">
                        {company_name}
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state)=>({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
})
export default connect(mapStateToProps) (Card13);