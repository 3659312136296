import React, { Component } from 'react'

export class Card23 extends Component {
    
  render() {
    const {comment, date, user_name, reply_avatar, reply_name,reply_date,reply_comment  } = this.props;
    return (
        <div className='flex flex-col gap-[20px]'>
            <div className='p-[65px] shadow-md max-[600px]:p-[17px]'>
                <div className=''>
                    {/* <div>
                        <img className=' w-[102px] h-[85px]' src={user} />
                    </div> */}

                    <div className='flex flex-col gap-[20px] w-[90%]'>
                        <div className='flex justify-between '>
                            <div>
                                <h4 className='font-[600] text-[#313e3b] text-[18px]'>{user_name}</h4>
                                {/* <span className='text-[14px] font-[400] text-[#828c8a]' >{date}</span> */}
                            </div>

                            {/* <div>
                                <button className='p-[6px_20px] border-[1px] rounded-[50px] text-[11px] border-[#828c8a] text-[#828c8a]'>
                                    REPLY
                                </button>
                            </div> */}
                            
                        </div>

                        <div>
                            <p className='text-[17px] font-[400] text-[#828c8a]'>
                                {comment}
                            </p>
                        </div>
                    </div>

                </div>
            </div>


           
           
        </div>
      
    )
  }
}



export default Card23