import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux'
import Card1 from '../components/cards/Card1';
import { getAcceptedStudent, getEnglishExamResults, getMainInfo } from '../actions/MainAction';
import StudentReviews from '../components/StudentReviews';
import Card27 from '../components/cards/Card27';
import LoadMoreBtn from '../components/LoadMoreBtn';
import Card20 from '../components/cards/Card20';
import { Helmet } from 'react-helmet';

export class AllSchools extends Component {
  componentDidMount(){
    const{getMainInfo, getEnglishExamResults, getAcceptedStudent, params}=this.props
    getMainInfo(this.props.lang_id);
    getEnglishExamResults(0.4, "");
    getAcceptedStudent(0.4, "", "")
  }

  loadMoreExamResults =(page)=>{
    const{getEnglishExamResults} = this.props;
    getEnglishExamResults(0.4*page, "")
  }

  loadMore=(page)=>{
    this.props.getAcceptedStudent(0.4*page)
  }


  render() {
    const{contentData, mainInfoData, englishExamResults, acceptedStudentsData} = this.props;
    // console.log(acceptedStudentsData);
    return (
      <div >
        <Helmet>
          <title>{contentData?.sh_schools}</title>
        </Helmet>
        <div className='container'>
          <SectionHeader title={contentData?.sh_schools}  />

          <div className='grid grid-cols-3 gap-[32px] justify-center max-[1100px]:grid-cols-3 max-[1100px]:gap-[26px] max-[800px]:grid-cols-2 max-[550px]:grid-cols-1'>
            <Card1
            // key={i}
            image={ mainInfoData.filter(x=>x.section_id==1)[0]?.image_full_url}
            path={ mainInfoData.filter(x=>x.section_id==1)[0]?.path? mainInfoData.filter(x=>x.section_id==1)[0]?.path:`/main-page/${ mainInfoData.filter(x=>x.section_id==1)[0]?.id}`}
            title={ mainInfoData.filter(x=>x.section_id==1)[0]?.translate?.title}
            text={ mainInfoData.filter(x=>x.section_id==1)[0]?.translate?.text}
            btn={ mainInfoData.filter(x=>x.section_id==1)[0]?.translate?.btn}
            btntype='1'
            
            />
             <Card1
              // key={i}
              image={ mainInfoData.filter(x=>x.section_id==1)[2]?.image_full_url}
              path={ mainInfoData.filter(x=>x.section_id==1)[2]?.path? mainInfoData.filter(x=>x.section_id==1)[2]?.path:`/main-page/${ mainInfoData.filter(x=>x.section_id==1)[2]?.id}`}
              title={ mainInfoData.filter(x=>x.section_id==1)[2]?.translate?.title}
              text={ mainInfoData.filter(x=>x.section_id==1)[2]?.translate?.text}
              btn={ mainInfoData.filter(x=>x.section_id==1)[2]?.translate?.btn}
              btntype='1'
              
            />

            <Card1
              // key={i}
              image={ mainInfoData.filter(x=>x.section_id==1)[1]?.image_full_url}
              path={ mainInfoData.filter(x=>x.section_id==1)[1]?.path? mainInfoData.filter(x=>x.section_id==1)[1]?.path:`/main-page/${ mainInfoData.filter(x=>x.section_id==1)[1]?.id}`}
              title={ mainInfoData.filter(x=>x.section_id==1)[1]?.translate?.title}
              text={ mainInfoData.filter(x=>x.section_id==1)[1]?.translate?.text}
              btn={ mainInfoData.filter(x=>x.section_id==1)[1]?.translate?.btn}
              btntype='1'
                    
            />
          </div>
        </div>

        <section className='mt-[52px]'>
          <div className='container'>
              
            <SectionHeader title={contentData?.sh_our_results} /> 
          
            <div className=' grid grid-cols-4 gap-[16px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
                
              {
                englishExamResults?.map((data,i)=>{
                  return(
                    <Card27
                      key={i}
                      first_name={data?.student?.first_name}
                      full_name={data?.student?.full_name}
                      last_name={data?.student?.last_name}
                      title={data?.exam?.lesson?.title}
                      score={data?.score}
                      company={data?.student?.company?.name}
                      company_id={data?.student?.company_id}
                      date={data?.created_at}
                      image_full_url={data?.student?.avatar_full_url}
                      url={data?.certificate?.certificate_full_url}
                      
                    />
                  )
                })
              }
            </div>

            {
              englishExamResults?.length >=8?
              <LoadMoreBtn onLoadData={this.loadMoreExamResults} defCount={8} count={englishExamResults?.length} />:null
            }

          </div>

        </section>


        <section className='mt-[52px]'>
          <SectionHeader title={contentData?.admitted_student} />

          <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
            {
              acceptedStudentsData?.map((data, i)=>{
                    return(
                      <Card20 
                      key={i}
                      first_name = {data?.first_name}
                      last_name = {data?.last_name}
                      company_name = {data?.company_name}
                      image_full_url = {data?.inst_image?.image_full_url}
                      country_image = {data?.country_image}
                      country_name={data?.country_name}
                      user_image={data?.user_image}
                      program_name={data?.program_name}
                      inst_name={data?.inst_name}
                      ranking={data?.ranking}
                      company_id={data?.company_id}
                      
                    /> 
                )
              })
            }
          </div>
          {
          acceptedStudentsData?.length >=8?
          <LoadMoreBtn onLoadData={this.loadMore} defCount={8} count={acceptedStudentsData?.length} />:null
          }
        </section>


        <section className='mt-[52px] bg-[#f9fbfb]  pb-[52px]'>
          <SectionHeader title={contentData?.students_say} />
          <StudentReviews grid_cols='grid-cols-3'/>
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) =>({
  mainInfoData: state.Data.mainInfoData,
  contentData: state.Data.contentData,
  englishExamResults: state.Data.englishExamResults,
  acceptedStudentsData: state.Data.acceptedStudentsData,
  lang_id: state.Data.lang_id,
})

const mapDispatchToProps ={getMainInfo, getEnglishExamResults, getAcceptedStudent}
export default connect(mapStateToProps, mapDispatchToProps)( AllSchools)