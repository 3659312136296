import React, { Component } from 'react'
import successImage from '../images/successImage.png'
import { connect } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer';

export class SuccessPopup extends Component {

  componentDidMount(){
    setTimeout(()=>{
      this.props.changeStateValue({name:"popUpVisible", value:false})
    }, 2000)
  }
  render() {
    const{ contentData } = this.props;
    return (
        <div className='success-popup'>
        <div className='success-popup-content m-[16pxy]'>
          {/* <p className='bg-[red]'>Tewekkürlər! Qeydiyyatınız uğurla tamamlandı.</p> */}
          <div className='p-[40px] '>
            <div className='flex justify-center'>
              <img src={successImage} className='w-[120px] h-[120px]' />
            </div>

            <div className='flex flex-col gap-[8px] mt-[32px]'>
              <span className='font-[600] text-[17px] text-[#333] text-center'> {contentData?.request_has_been_accepted}</span>
              <span className='font-[600] text-[17px] text-[#333] text-center'> {contentData?.will_call_you}</span>
              <span className='font-[600] text-[17px] text-[#333] text-center'> {contentData?.number_verification}</span>
              <span className='font-[600] text-[17px] text-[#333] text-center'> {contentData?.enter_your_number}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) =>({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SuccessPopup)