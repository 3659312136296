import React, { Component } from 'react';
import SectionHeader from '../components/SectionHeader';
import BlogCard from '../components/cards/BlogCard';
import { getBlogs } from '../actions/MainAction';
import { connect } from 'react-redux';
import LoadMoreBtn from '../components/LoadMoreBtn';
import { Helmet } from 'react-helmet';

export class Blog extends Component {
  componentDidMount() {
    const{getBlogs}=this.props;
    getBlogs(this.props.lang_id, 0.8)
    // this.props.getBlogs(this.props.lang_id, 1);
  }

  loadMore=(page)=>{
    this.props.getBlogs(this.props.lang_id, page)
  }

  render() {
    const { blogsData, contentData } = this.props;
    // console.log(blogsData);
   

    return (
      <div>
        <Helmet>
          <title>{contentData?.sh_blogs}</title>
        </Helmet>
        <div className='border-b-[1px] pb-[52px] container'>
          <SectionHeader title={contentData?.sh_blogs} />
        </div>
        <section className='container'>
          <div className='blog-container flex items-start gap-[40px]  mt-[32px] '>
            <div className='w-full '>
              <BlogCard 
              width='w-full h-[550px] '
              title={blogsData[0]?.title}
              // date={blogsData[0]?.date} 
              path={blogsData[0]?.path}
              image={blogsData[0]?.image_full_url}
              />                               
            </div>

            <div className='blog-card flex flex-col w-[50%] gap-[38px] son'>
                {
                    blogsData?.map((data, i)=>{
                        if(i>0 && i <=3){
                            return(
                                <BlogCard
                                key={i}
                                justify_between='justify-between'
                                flex='flex'
                                width='w-1/2'
                                border='border-b-[1px]'
                                image={data?.image_full_url}
                                title={data?.title}
                                // date={data?.date} 
                                path={data?.path}
                                />
                            )
                        }
                       
                    })
                }
            </div>
          </div>
        </section>

        <section className='bg-[#FAFAFA] pt-[32px] pb-[32px] mt-[52px] and'>
          <div className='container'>
            <div className='grid grid-cols-4 gap-[32px] max-[700px]:grid-cols-2 max-[500px]:grid-cols-1 '>
            {
                blogsData.map((data, i) => {
                    if(i>3){
                        return(
                          <BlogCard
                          key={i}
                          image={data?.image_full_url}
                          card_w='w-[w-full]'
                          width='w-full h-[180px]'
                          title={data?.title}
                          // date={data?.date}
                          path={data?.path}
                          />
                        )
                    }
                  
                })
              }
            </div>
            {
          blogsData?.length >=4?
          <LoadMoreBtn onLoadData={this.loadMore} defCount={4} count={blogsData?.length} />:null
            }
          </div>
        </section>

        {/* <Card14 text={contentData?.blog_newsletter} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  blogsData: state.Data.blogsData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
});

const mapDispatchToProps = { getBlogs };

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
