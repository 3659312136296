import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card19 from '../components/cards/Card19'
import { connect } from 'react-redux';
import { getGraduats, getKidsEnglishType, } from '../actions/MainAction';
import { Link } from 'react-router-dom';
import Card13 from '../components/cards/Card13';
import Card29 from '../components/cards/Card29';
import { Helmet } from 'react-helmet';

export class SingleSchools extends Component {

    componentDidMount(){
      // this.props.getAcceptedStudent(0.4);
      this.props.getGraduats(0.4)
      this.props.getKidsEnglishType(this.props.lang_id, this.props.schoolType)
    }

    loadMore=(page)=>{
        this.props.getAcceptedStudent(page)
      }
  render() {
  
    const {graduatsData, contentData, kidsEnglishTypeData, faqs=[]} = this.props;
    // console.log(kidsEnglishTypeData);
    return (
        <div className=' mt-0'>
          <Helmet>
            {
              this.props.schoolType ==1?
              <title>High School</title>:null
            }

            {
              this.props.schoolType ==2?
              <title>Primary School</title>:null
            }

            {
              this.props.schoolType ==3?
              <title>Secondary School</title>:null
            }
          </Helmet>

          {
            this.props.schoolType ==1?
            <section className='container flex items-start gap-[32px] min-h-[500px] max-[850px]:flex-col max-[850px]:items-center  '>

              <div className='embed_header_video h-full  mt-[10px] w-1/2 max-[850px]:w-full' >
                <iframe 
                  src="https://www.youtube.com/embed/COaJU62GF6M?si=bzZ52Hrfl-spAF-F" 
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  referrerpolicy="strict-origin-when-cross-origin" 
                  allowfullscreen>
                </iframe>

              </div>

              <Card29 
                title='High School'
                description={contentData?.high_school_description} 
                text1={contentData?.high_school_text1}
                text2={contentData?.high_school_text2}
                
                />
            </section>:null
          }

          {
            this.props.schoolType ==2?
            <section className='container flex items-start gap-[32px] min-h-[500px] max-[850px]:flex-col max-[850px]:items-center  '>
                <div className='embed_header_video h-full  mt-[10px] w-1/2 max-[850px]:w-full' >
                <iframe 
                  src="https://www.youtube.com/embed/Z8QBpwIyaJI?si=rgbrmbBmLGMTb_lK" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  referrerpolicy="strict-origin-when-cross-origin" 
                  allowfullscreen>
                </iframe>
                </div>

              <Card29 
              title='Primary School'
              description={contentData?.primary_school_description}
              text1={contentData?.primary_school_text1}
              text2={contentData?.primary_school_text2}
              
              />
            </section>:null
          }

          {
            this.props.schoolType ==3?
            <section className='container flex items-start gap-[32px] min-h-[500px] max-[850px]:flex-col max-[850px]:items-center  '>
                <div className='embed_header_video h-full  mt-[10px] w-1/2 max-[850px]:w-full' >
                  <iframe 
                    src="https://www.youtube.com/embed/cOAaxF6zJPU?si=Sw4Wjghcydb9dPXc" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen>
                  </iframe>
                </div>
              <Card29 title='Secondary School'
              description={contentData?.school_secondary_description}
              text1={contentData?.school_secondary_text1}
              text2={contentData?.school_secondary_text2}
              />

            </section>:null
          }



          

          {
            kidsEnglishTypeData.length > 0 ?
            <section className='bg-[#f5f7f7] mt-[52px] pb-[52px]' >
            <div className='container'>
            <SectionHeader title="Diplomunu Seç" />
              <div className=' grid grid-cols-3 gap-[32px] max-[1080px]:grid-cols-2 max-[690px]:grid-cols-1 max-[420px]:gap-[50px]'>
                {
                  kidsEnglishTypeData?.map((data, i)=>{
                    return(
                      <Card19 
                      key={i}
                      image={data?.image_full_url}
                      lvl_diploma={data?.translate?.title}
                      text={data?.translate?.text}
                    />
                    )
                  })
                }
              </div>
            </div> 
          </section> :null    

          }
            

            {/* <section className='container' >
              <SectionHeader title={contentData?.nav_premium_graduent} />
              <div className='grid grid-cols-4 gap-[32px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[480px]:grid-cols-1 '>
                <Card21 />
                <Card21 />
                <Card21 />
                <Card21 />
              </div>
            </section> */}


            <section>
              {
                graduatsData.length > 0?
                <SectionHeader title={contentData?.nav_1} />:null
              }
              <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
                  
                {
                  graduatsData?.map((data, i)=>{
                    return(
                      <Card13 
                        key={i}
                        first_name = {data?.first_name}
                        last_name = {data?.last_name}
                        company_name = {data?.company_name}
                        image_full_url = {data?.inst_image?.image_full_url}
                        country_image = {data?.country_image}
                        country_name={data?.country_name}
                        user_image={data?.user_image}
                        program_name={data?.program_name}
                        inst_name={data?.inst_name}
                        ranking={data?.ranking}
                        grade={data?.grade}
                        company_id={data?.company_id}
                        
                      /> 
                    )
                  })
                } 
              </div>        
                  
              <div className=' flex justify-center  mt-[12px] ' >
                <Link to='/xaricde-oxuyanlar/'>
                  <button className='text-[var(--blue)] p-[6px_16px]  text-[16px] font-[600] border-[var(--blue)] rounded-[20px] border-[1px]'>
                    {contentData?.btn_view_all} 
                  </button>
                </Link>
              </div>  
              
              {/* {
                this.props.schoolType ==1?
                comp:null
              } */}
            </section>

          {/* <section className='container'>
              <SectionHeader title={contentData?.sh_schools} />
              
              <div>
                <SchoolsCompanies />
              </div>
          </section> */}
                
          {/* <div className='flex flex-col'> 
            <FaqSection faqs={faqs} page_type="school_page" />
          </div> */}

        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  graduatsData: state.Data.graduatsData,
  lang_id: state.Data.lang_id,
  contentData : state.Data.contentData,
  kidsEnglishTypeData: state.Data.kidsEnglishTypeData,

})
const mapDispatchToProps = {getGraduats, getKidsEnglishType}
export default connect(mapStateToProps, mapDispatchToProps)(SingleSchools)