import React, { Component } from 'react'
import moment from 'moment';
import ExamSvg from '../svg/ExamSvg';
import ScoreSvg from '../svg/ScoreSvg';
import DateSvg from '../svg/DateSvg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCompanyPath } from '../../actions/company_path';

export class Card27 extends Component {
    // Tarihi formatlamak için bir fonksiyon
  formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY'); 
  }

  splitTitle = (title) => {
    if (title.length > 19) {
      return title.substring(0, 19) + "..";
    } else {
      return title;
    }
  }

  // getNameText(name) {
  //   const splt = name.split(' ');
  //   let txt = '';
  //   txt += splt[0][0].toUpperCase();
  //   if (splt[1]) {
  //     txt += splt[1][0].toUpperCase();
  //   }

  //   return txt;
  // }

  getNameText(name) {
    const splt = name.split(' ');
    let txt = '';
    if (splt.length > 0) {
      txt += splt[0][0].toUpperCase(); // İlk kelimenin ilk harfi
      if (splt.length > 1) {
        txt += splt[splt.length - 1][0].toUpperCase(); // Son kelimenin ilk harfi
      }
    }
    return txt;
  }
  render() {
    const { title, full_name, first_name, last_name, score, image_full_url, date, company, url = "", company_id, contentData } = this.props;
    return (
      <div className='rounded-[12px] border-[1px] shadow flex flex-col items-center p-[16px]'>
        <div className='flex flex-col w-full items-center gap-[4px] mb-[15px]'>
          <div className='p-[2px] border-[1px] rounded-[50px]'>
            {image_full_url ? (
              <img src={image_full_url} className='rounded-[50px] w-[70px] h-[70px]' alt='User Avatar' />
            ) : (
              <span className='w-[70px] h-[70px] rounded-[100%] inline-flex justify-center items-center text-white text-[27px] font-[500] bg-blue-700'>
                {this.getNameText(full_name)}
              </span>
            )}
          </div>

          <div className='flex items-center gap-[6px]'>
            <h4 className='font-[400] text-[18px] text-center text-[#000]'>{this.getNameText(first_name)}.</h4>
            <h4 className='font-[400] text-[18px] text-center text-[#000]'>{last_name}</h4>
          </div>

          <span className='text-[13px] font-[400] text-[#ACACAC]'>
            {contentData?.student}
          </span>

          <Link to={getCompanyPath(company_id)}>
            <span className='text-[var(--blue)] font-[600] text-[13px]'>
              {company}
            </span>
          </Link>
        </div>

        <div className='flex flex-col items-center  gap-[8px] pt-[12px] w-full border-dashed border-t-[1px] border-[#ACACAC]'>
          <div className='flex flex-col gap-[8px] '>
            <div className='flex items-center gap-[10px]'>
              <ExamSvg />
              <div className='flex items-center gap-[2px]'>
                <span className='text-[15px] font-[200] text-[#000]'>{title}</span>
              </div>
            </div>

            <div className='flex items-center gap-[10px]'>
              <ScoreSvg fill='#545454' width='20' height='20' />
              <div className='flex items-center gap-[2px]'>
                <span className='text-[15px] font-[200] text-[#000]'>{contentData?.score} :</span>
                <span className='text-[14px] font-[300] text-[#000]'> {score}</span>
              </div>
            </div>

            <div className='flex items-center gap-[10px]'>
              <DateSvg />
              <div className='flex items-center gap-[2px]'>
                <span className='text-[15px] font-[200] text-[#000]'>{contentData?.date} :</span>
                <span className='text-[14px] font-[300] text-[#000]'> {this.formatDate(date)}</span>
              </div>
            </div>
          </div>
          
        </div>  
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
})

export default connect(mapStateToProps)(Card27)